<template>
  
    <a-card class="template-card" :bodyStyle="{padding:`0px`}" v-if="item.name !== 'Blank Template'" @mouseover="hovering = true" @mouseout="hovering = false" hoverable :class="{hovering: hovering}" style="background: white; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16); border: none;border-color: var(--orange);" >
        <div class="img-container">
			<img
				class="img-cover"
				:alt="email.name"
				:src="email.image? email.image : 'https://media.istockphoto.com/vectors/plus-sign-isolated-on-white-background-button-with-symbol-for-your-vector-id903033754'"
				slot="cover"
			/>
		</div>
        <div class="dF aC px-4 py-3">
            <div class="f1">
                <h6 class="mb-0" style="font-size:13px">{{email.name}}</h6>
                <!-- <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo</div> -->
            </div>
            <div>
                <!-- <a-icon style="line-height: 40px;" type="eye" /> -->
            </div>
        </div>
    </a-card>

    <a-card v-else :bodyStyle="{display:`flex`,flexDirection:'column', justifyContent:'space-between', height:`100%`}"  @mouseover="hovering = true" @mouseout="hovering = false" hoverable :class="{hoveringBlank: hovering}" style="background: white; box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16); border: 2px dashed #D0C9D6; " >
        <span></span>
        <div style="width: 100%; text-align: center;">
            <img
                alt="example"
                src="@/assets/blank.svg"
            />
        </div>
        <h6 style="text-align: center;">Add Blank</h6>
    </a-card>

    
</template>

<script>
import {formatDate} from 'bh-mod'
export default {
    props:['item'],
    data() {
        return {
            hovering:false
        }
    },
    computed:{
       email(){
           if (this.item.email && this.item.email.id) return this.item.email 
           return this.item
       },
       getDate(){
           let prefix = 'Last modified:'
           if (this.item.email && this.item.email.id) prefix = 'Sent on:'
           return `${prefix} ${formatDate(this.item.updatedAt)}`
       }
     
    },
    methods:{
       formatDate
    }
}
</script>

<style>
	.img-cover {
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		object-fit:cover;
	}
	.img-container {
		height:0;
		overflow:hidden;
		padding-bottom:150%;
		position: relative;
	}
    .template-card{
        border:1px solid transparent  !important;
    }
    .hovering{
        border: 1px solid var(--orange) !important;
    }
    .hoveringBlank{
        border: 2px dashed var(--orange) !important;
    }

    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
</style>