<template>
    <div style="width:100%; background-color: #F7F5F9; overflow: auto;">

        <TemplateDrawer :templateName="templateName" />

        <div class="dF jSB">
            <h4 style="margin-left: 15px;" class="mb-3 mt-3">{{emailType}}</h4>
        </div>

        <div :class="[emailType === 'Choose a starting point'? 'contactspage2' : '']" style="background-color: white; padding-right: 0; padding-left: 0; margin-bottom: 15px; margin-right: 15px; margin-left: 15px;">
            <a-steps v-if="current === 1" :current="current" class="mb-5" style="width: 70%; padding-top: 25px; padding-left: 25px;">
                <a-step v-for="item in steps" :key="item.title" :title="item.title">
                    <span slot="description">{{item.description}}</span>
                </a-step>
            </a-steps>

            <a-card v-if="current === 0">
                <a-steps :current="current" class="mb-5" style="width: 70%;  padding-right: 25px;">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title">
                        <span slot="description">{{item.description}}</span>
                    </a-step>
                </a-steps>

                <a-form ref="ruleForm">
                    <a-form-item ref="name" label="Name this template" prop="name">
                        <a-input
                            v-model="templateName"
                            placeholder="Add Template name"
                            size="large"
                            style="width: 70%;"
                        />
                    </a-form-item>
                </a-form>

                <div class="mt-5" style="width: 100%; text-align: right;">
                    <a-button @click="cancelBroadcast" class="mr-3" style="width: 100px; height: 35px;">CANCEL</a-button>
                    <a-button @click="nextStep" type="primary" style="width: 100px; height: 35px;">NEXT</a-button>
                </div>

            </a-card>

            <div v-if="current === 1" style="padding: 25px;">


				<a-tabs default-active-key="1">
					<a-tab-pane key="1">
						<span slot="tab">
							Predefined Templates
						</span>
						<div class="template-card-container px-2">
							<NewTemplateCard v-for="(item) in templatesTransformed" :item="item" @click.native="dupNewTemplate(item)" class="mb-5" :key="item.id"/>
						</div>
					</a-tab-pane>
					<a-tab-pane key="2">
						<span slot="tab">
							<a-icon type="star" />
							Your Saved Templates
						</span>
						<div class="template-card-container px-2">
							<TemplateCard v-for="(item) in savedTemplates" :item="item" @click.native="dupTemplate(item)" class="mb-5" :key="item.id"/>
						</div>
					</a-tab-pane>
				</a-tabs>

				<div class="mt-5 dF jSB" style="width: 100%; text-align: right;">
					<a-button @click="current = 0" class="mr-3" style="width: 100px; height: 35px;">BACK</a-button>
					<span />
				</div>
			</div>

        </div>
    </div>
</template>

<script>
    import {getBase64,arrToObj,formatDate,validateEmail,getCookie} from 'bh-mod'
    import TemplateDrawer from '@/components/email/TemplateDrawer'
	import NewTemplateCard from '@/components/email/NewTemplateCard'
	import TemplateCard from '@/components/email/TemplateCard'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import Flickity from 'vue-flickity'
    import BHLoading from 'bh-mod/components/common/Loading'
    import moment from 'moment';
    import axios from 'axios'

    export default {
        name:'NewTemplateView',
        components:{
            TemplateDrawer,NewTemplateCard,TemplateCard,BHLoading
        },
        data() {
            return {
                templateName:'',
                recepientLength:0,
                tags:[],
                email:{},
                sendNow:true,
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1',
                testEmail:[],
                value:'now',

                rules: {
                    name: [
                        { required: true, message: 'Please input a template name', trigger: 'blur' },
                    ],
                },

                current: 0,
                steps: [
                    {
                        title: 'Step 1',
                        description: 'Define Template Info'
                    },
                    {
                        title: 'Step 2',
                        description: 'Content Design'
                    },
                ],
				templates:[]
            }
        },
        watch:{
            currentStep(){
                console.log('CURRENT STEP RAN')
                this.$store.commit('EMAIL_TYPE', {value:'Add New Broadcast'})
                this.current = 2
            },
        },
        computed:{
            currentStep(){
                return this.$store.state.step
            },
            sender(){
                console.log('SENDERSSSSS', this.$store.state.email.senders)
                let senders = this.$store.state.email.senders
                for (var i in senders){
                    // console.log('iiii', i)
                    return senders[i]
                }
            },
            emailType(){
                return this.$store.state.emailType
            },
            broadcasts(){
                return this.$store.state.email.broadcasts
            },
			templatesTransformed(){
				if (this.templates && this.templates.length){
					let res = this.templates
					console.log('TEMPLATES TRANSFORED', res)
					let index = res.findIndex(x => x.templateSlug == 'blank-template');
					let blank = res[index];
					res = res.filter(x => x.templateSlug != 'blank-template');
					res = [blank,...res]
					return res
				} else {
					return []
				}
			},
            savedTemplates(){

                let templates = Object.values(this.$store.state.email.templates)

                let customs = []
                templates.forEach( tmp => {
                    if (tmp.instance === this.$store.state.instance.id) customs.push(tmp)
                })

                return customs

            },
            siteURL(){
                return this.$store.state.email.siteURL
            },
            drafts(){
                return this.$store.state.email.drafts
            },
            searchOBJ(){
                return this.$store.state.email.searchOBJ
            },
            resultLength(){
                return this.$store.state.email.resultLength
            },
            showingContactMode(){
                return this.listMode === 'all' ? `Showing All Contacts (${this.resultLength} Contacts) `: `Found ${this.resultLength} Contacts `
            },
            newStoreTime(){
                return this.$store.state.email.newStoreTime
            },
            instance(){
                return this.$store.state.instance
            },
            pageSize(){
                return this.$store.state.email.pageSize
            },
            query(){
                return this.$store.getters.query
            },
            dialog(){
                return this.$store.state.email.broadcastModal
            },
            loadingPage:{
                get(){
                    return this.$store.state.email.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            backToEditor(){
                this.$store.commit('SHOW_EDIT_TEMPLATE',this.email)
				setTimeout(() => {
					this.current = 1;
				}, 500)
            },
            emailBuilt(email){
                console.log('NEXTTT')
                this.email = email
            },
            moment,
            lastStep(){
                this.$refs.step3form.validate(valid => {
                    if (valid) {
                        this.current = 3
                    } else {
                        console.log('error!!');
                        return false;
                    }
                });

            },

            packageEmail(){

                let fontGen = (font) => {
                    return `<link data-font href="https://fonts.googleapis.com/css?family=${font.name.split(' ').join('+')}:${font.weights.join(',')}" rel="stylesheet" type="text/css">`
                }
                let fontGenStyle = (font) => {
                    return `@import url(https://fonts.googleapis.com/css?family=${font.name.split(' ').join('+')}:${font.weights.join(',')});`
                }


                if (!this.dialog.data.html) return this.$message.error('No HTML found')

                let emailHTML = this.dialog.data.html
                let html = document.createElement('html')
                html.innerHTML = emailHTML

                let parentNode = html.querySelector('body')
                let refChild = html.querySelector('body').children[0]
                let previewDOM = document.createElement('div')

                previewDOM.innerHTML = `<div style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">
                                        </div>`

                parentNode.insertBefore(previewDOM, refChild)
                html = html.innerHTML
                html = `<!DOCTYPE html>↵<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">` + html + `</html>`
                html = html.replace(/\n/ig, '');
                html = html.replace('↵','')
                html = html.replace('https://mjml.io','')
                let fields = [
                    {search:/%%COMMUNITYNAME%/gi, replace:this.instance.name},
                    {search:/%%CONTACT_INFO%/gi, replace:`${this.instance.address}, ${this.instance.city} ${this.instance.region}`},
                    {search:/%%name/gi, replace:'%recipient_name'},
                    {search:/%%fname%/gi, replace:'%recipient.firstName%'},
                    {search:/%%lname%/gi, replace:'%recipient.lastName%'},
                    {search:/%%email%/gi, replace:'%recipient_email%'},
                    {search:/%%5/gi, replace:'%recipient.5'},
                ]

                let fonts = this.dialog.data.options && this.dialog.data.options.fonts? this.dialog.data.options.fonts : false
    //
    //           <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
    //           <style type="text/css">
    //                 @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
    //           </style>
    //     <!--<![endif]-->
                if (fonts){
                    let fontHTML = fonts.map(x => fontGen(x)).join(' ')
                    fontHTML = fontHTML  + `<style data-font>${fonts.map(x => fontGenStyle(x)).join(' ')}</style>`
                    html = html.replace('<style', `<!--[if mso]>
                                        <xml>
                                        <o:OfficeDocumentSettings>
                                        <o:AllowPNG/>
                                        <o:PixelsPerInch>96</o:PixelsPerInch>
                                        </o:OfficeDocumentSettings>
                                        </xml>
                                        <![endif]-->
                                    <!--[if lte mso 11]>
                                        <style type="text/css">
                                        .outlook-group-fix { width:100% !important; }
                                        </style>
                                        <![endif]--><!--[if !mso]><!-->${fontHTML}<!--<![endif]--> <style`)
                }

                fields.forEach( ({search,replace}) => {
                    html = html.replace(search,replace)
                })

                return html
            },
            nextStep(){

                if (this.current == 0){

                    if (!this.templateName.trim()) return this.$message.error('Please enter a template name')
                    this.current = 1
					this.$store.commit('EMAIL_TYPE', {value: 'Choose a starting point'})
					if (!this.templates.length){
						axios.post(`${this.$store.state.email.siteURL}/list/templates-email`).then( ({data}) => {
							this.templates = Object.values(data)
						})
					}
                }
            },
            cancelBroadcast(){
                this.$router.push(`/`)
                this.$store.commit('EMAIL_TYPE', {value:'Drafts'})
            },
            async bulkActioned({key,keyPath,val}){
                let self = this
                console.log('KEY',key)

                if (key == 'delete') {
                    this.$confirm({
                        title: "Delete",
                        content: h => <div>Do you want to delete these forms/surveys?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            for (var i=0; i<self.selectedRowKeys.length; i++) {
                                let id = self.selectedRowKeys[i]
                                console.log('ID', id)
                            }
                            setTimeout(() => {
                                self.selectedRowKeys = []
                            }, 1000);
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                }
            },
            formatDate,
            getDeliveredPerc({counts,contacts}){
                let {delivered = 0} = counts
                return (delivered / contacts.length) * 100
            },
            async dupTemplate({id}){
                this.$store.commit('LOAD',true)

                let {data} = await this.$api.get(`/projects/:instance/${id}`).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})

                let {id:id2,_id, pages = [],...finalObj} = data

                let page = pages[0]

                if (!page) {
                    this.$message.error('There is a problem with this Template, Please choose another!')
                    return this.$store.commit('LOAD', false)
                }

                delete page._id
                delete page.id

				if(page.header){
					delete page.header.id
					delete page.header._id
					page.header.id = 'add_header'
				}
                if (page.footer){

                    delete page.footer.id
                    delete page.footer._id
					page.footer.id = 'add_footer'
                }

                let {sections = []} = page
                sections = sections.map(({_id,id,...sec}) => {
					sec.id = 'add_' + id
					return sec
				})

                page.sections = sections

                finalObj.page = page
                // finalObj.SUBJECT = this.broadcast.subject
                // finalObj.PREVIEW = this.broadcast.preheader
                finalObj.broadcast = this.broadcast
                this.$store.commit('SHOW_EDIT_TEMPLATE',finalObj)

            },
			async dupNewTemplate(item){
                this.$store.commit('LOAD',true)
				let __bhjt = getCookie('__bhjt')
				let sendObj = {
					name: item.name,
					template: item.templateSlug,
					type:'email',
					__bhjt,
					_global: item._global
				}
				console.log('DUPLICATING NEW TEMPLATE', sendObj)

                let {data} = await axios.post(this.siteURL + `/create-email/` + this.$store.state.instance.id, sendObj)

                let {id:id2,_id, pages = [],...finalObj} = data

                let page = pages[0]

                if (!page) {
                    this.$message.error('There is a problem with this Template, Please choose another!')
                    return this.$store.commit('LOAD', false)
                }

                delete page._id
                delete page.id

                delete page.header.id
                delete page.header._id
				page.header.id = 'add_header'
                if (page.footer){

                    delete page.footer.id
                    delete page.footer._id
					page.footer.id = 'add_footer'
                }

                let {sections = []} = page
				let index = 0
                sections = sections.map(({_id,id,...sec}) => {
					sec.id = 'add_' + Date.now() + index
					index++
					return sec
				})

                page.sections = sections

                finalObj.page = page
                // finalObj.SUBJECT = this.broadcast.subject
                // finalObj.PREVIEW = this.broadcast.preheader
                finalObj.broadcast = this.broadcast
                this.$store.commit('SHOW_EDIT_TEMPLATE',finalObj)

            },
            closeDuplicate(){
                if (this.duplicate.load) return

                this.duplicate = {
                    show:false,
                    name:'',
                    id:''
                }
            },

        },
        mounted(){

        },
        created() {

            this.$store.commit('EMAIL_TYPE', {value:'Add New Broadcast'})

            this.$api.get(`/tags/:instance?type=contacts`).then( ({data}) => {
                this.tags = data.filter(x => x.type === "contacts").map( ({id,name}) => ({label:name,value:id}) )
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})

            this.templateName = `New Template on ${formatDate(Date.now())}`

        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .contactspage2{
        @media screen and (min-width:567px){
            max-width: calc(100% - 35px) !important;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            max-width: 70%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }



</style>

<style lang="scss">
    .template-card-container{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        gap:20px;
        @media screen and (min-width:900px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (min-width:1600px){
            grid-template-columns: repeat(5, 1fr);
        }
    }
    .email-template-cards{
        .ant-card{
            &:hover{

            }
        }
        .ant-card-actions{
            background:var(--light-purple);
            span{
                color:var(--purple);
            }
        }
        .ant-card-body{
            // background:#fff5e8;
            background: white;
            padding: 0;
        }
        .ant-card-cover{
            height: 300px;
            overflow: hidden;
            position:relative;
            background:#eaedf2;
            img{
                position:absolute;
                top:0;
                width:100%;
                left:0;
                transition: all .6s ease-out;
            }
        }
        .ant-card{
            &:hover{
                .ant-card-cover img{
                    transform: translateY(calc(-100% + 301px));
                }
            }
        }
    }
</style>
